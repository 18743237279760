<template>
  <section id="dashboard-ecommerce">
    
  </section>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
